import React from "react";
import ScrollToTop from "./components/scroll/ScrollToTop";
import PublicRouter from "./components/routers/PublicRouter";
import PublicTemplate from "./components/templates/PublicTemplate";

import { BrowserRouter as Router, Switch } from "react-router-dom";

// APP CSS
import "./App.css";

// PUBLIC
import Home from './pages/public/Home';
import PageNotFound from './pages/public/PageNotFound';

function App() {
	return (
		<Router>
			<ScrollToTop>
				<Switch>
					<PublicRouter exact path="/" component={Home} template={PublicTemplate} title="Davy Group" />
					<PublicRouter component={PageNotFound} template={PublicTemplate} title="Davy Group" />
				</Switch>
			</ScrollToTop>
        </Router>
  	)
}

export default App;
