import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import { useHistory } from 'react-router-dom';
import { Link } from "react-router-dom";
import { AppBar as MuiAppBar, Toolbar, CssBaseline, Box, Divider, Drawer, IconButton, List, ListItem, ListItemText, ListItemIcon, Typography } from '@mui/material';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { ReactComponent as LogoIllustration } from "../../../assets/logo.svg";
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import Financials from '../../menus/Financials';
import Updates from '../../menus/Updates';
import Governance from '../../menus/Governance';
import Other from '../../menus/Other';
import dataJSON from "../../../data/company.json";

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  minHeight: '56px',
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: '0px',
    width: '100%',
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const PublicTemplate = ({children}) => {
  const history = useHistory();
  const mode = 'light';
  const financials = dataJSON.content[0].data;
  const updates = dataJSON.content[1].data;
  const governance = dataJSON.content[2].data;
  const other = dataJSON.content[3].data;
  const [open, setOpen] = useState(false);
  const [openSub1, setOpenSub1] = useState(false);
  const [openSub2, setOpenSub2] = useState(false);
  const [openSub3, setOpenSub3] = useState(false);
  const [openSub4, setOpenSub4] = useState(false);
  
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
    setOpenSub1(false);
    setOpenSub2(false);
    setOpenSub3(false);
    setOpenSub4(false);
  };

  const handleSub1Open = () => {
    setOpenSub1(true);
  }

  const handleSub1Close = () => {
    setOpenSub1(false);
  }

  const handleSub2Open = () => {
    setOpenSub2(true);
  }

  const handleSub2Close = () => {
    setOpenSub2(false);
  }

  const handleSub3Open = () => {
    setOpenSub3(true);
  }

  const handleSub3Close = () => {
    setOpenSub3(false);
  }

  const handleSub4Open = () => {
    setOpenSub4(true);
  }

  const handleSub4Close = () => {
    setOpenSub4(false);
  }

  const theme = React.useMemo(
    () =>
      createTheme({
        palette: {
          background: {
            default: '#f9f9f9'
          },
          primary: {
            main: '#212121',
          },
          mode,
        },
      }),
    [mode],
  );

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ display: 'flex'}}>
        <CssBaseline />
        <AppBar className="d-none d-lg-block" position="fixed" enableColorOnDark={false} sx={{ bgcolor: theme.palette.background.default}}>
          <Toolbar disableGutters style={{paddingLeft: '16px', paddingRight: '16px', minHeight: '56px'}}>
              <div style={{paddingLeft: '0px', paddingRight: '0px', display: "inline-flex", color: theme.palette.primary.main, cursor: 'pointer'}} onClick={() => {
                history.push('/');
              }}><LogoIllustration height="28px" /></div>
              <div style={{marginLeft: "auto", marginRight: "0px"}}>
                  <Financials/>
                  <Updates/>
                  <Governance/>
                  <Other/>
              </div>
          </Toolbar>
        </AppBar>
        <AppBar className="d-block d-lg-none" position="fixed" enableColorOnDark={false} sx={{ bgcolor: theme.palette.background.default}}>
            <Toolbar disableGutters style={{paddingLeft: '16px', paddingRight: '16px', minHeight: '56px'}}>
                <div style={{paddingLeft: '0px', paddingRight: '0px', display: "inline-flex", color: theme.palette.primary.main, cursor: 'pointer'}} onClick={() => history.push('/')}><LogoIllustration height="28px" /></div>
                <div style={{marginLeft: "auto", marginRight: "0px"}}>
                  <IconButton
                    color="primary"
                    aria-label="open drawer"
                    onClick={handleDrawerOpen}
                    edge="end"
                    sx={{
                      marginLeft: '5px',
                      ...(open && { display: 'none' }),
                    }}
                  >
                    <MenuIcon />
                  </IconButton>
                  <IconButton
                    color="primary"
                    aria-label="close drawer"
                    onClick={handleDrawerClose}
                    edge="end"
                    sx={{
                      marginLeft: '5px',
                      ...(!open && { display: 'none' }),
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                </div>
            </Toolbar>
        </AppBar>
        <Drawer
          anchor="right"
          open={open}
          hideBackdrop={false}
          onClose={handleDrawerClose}
          className="d-block d-lg-none"
        >
          <Box style={{ maxWidth: '100%', width: '320px'}}>
            <Divider sx={{ mt: '56px' }}/>
            <List sx={{ p: 0 }}>
                {!openSub1&&!openSub2&&!openSub3&&!openSub4&&<>
                    <ListItem button key="Financials" onClick={handleSub1Open}>
                        <ListItemText primary={<Typography color="textPrimary">Results & Financials</Typography>} />
                        <ListItemIcon sx={{minWidth: '32px'}}><KeyboardArrowRightIcon /></ListItemIcon>
                    </ListItem>
                    <ListItem button key="Updates" onClick={handleSub2Open}>
                        <ListItemText primary={<Typography color="textPrimary">Updates</Typography>} />
                        <ListItemIcon sx={{minWidth: '32px'}}><KeyboardArrowRightIcon /></ListItemIcon>
                    </ListItem>
                    <ListItem button key="Governance" onClick={handleSub3Open}>
                        <ListItemText primary={<Typography color="textPrimary">Governance</Typography>} />
                        <ListItemIcon sx={{minWidth: '32px'}}><KeyboardArrowRightIcon /></ListItemIcon>
                    </ListItem>
                    <ListItem button key="Other" onClick={handleSub4Open}>
                        <ListItemText primary={<Typography color="textPrimary">Other</Typography>} />
                        <ListItemIcon sx={{minWidth: '32px'}}><KeyboardArrowRightIcon /></ListItemIcon>
                    </ListItem>
                </>}
                {openSub1&&!openSub2&&!openSub3&&!openSub4&&<>
                    <ListItem button key="Back" onClick={handleSub1Close}>
                        <ListItemIcon sx={{minWidth: '32px'}}><KeyboardArrowLeftIcon /></ListItemIcon>
                        <ListItemText primary={<Typography color="textPrimary">Back</Typography>} />
                    </ListItem>
                    <Divider />
                    {financials.map((item) =>
                      <Link to={item.path} key={item.label} style={{textDecoration:'none'}} onClick={handleDrawerClose}>
                        <ListItem button key={item.label}>
                            <ListItemText primary={<Typography color="textPrimary">{item.label}</Typography>} />
                        </ListItem>
                      </Link>
                    )}
                </>}
                {!openSub1&&openSub2&&!openSub3&&!openSub4&&<>
                    <ListItem button key="Back" onClick={handleSub2Close}>
                        <ListItemIcon sx={{minWidth: '32px'}}><KeyboardArrowLeftIcon /></ListItemIcon>
                        <ListItemText primary={<Typography color="textPrimary">Back</Typography>} />
                    </ListItem>
                    <Divider />
                    {updates.map((item) =>
                      <Link to={item.path} key={item.label} style={{textDecoration:'none'}} onClick={handleDrawerClose}>
                        <ListItem button key={item.label}>
                            <ListItemText primary={<Typography color="textPrimary">{item.label}</Typography>} />
                        </ListItem>
                      </Link>
                    )}
                </>}
                {!openSub1&&!openSub2&&openSub3&&!openSub4&&<>
                    <ListItem button key="Back" onClick={handleSub3Close}>
                        <ListItemIcon sx={{minWidth: '32px'}}><KeyboardArrowLeftIcon /></ListItemIcon>
                        <ListItemText primary={<Typography color="textPrimary">Back</Typography>} />
                    </ListItem>
                    <Divider />
                    {governance.map((item) =>
                      <Link to={item.path} key={item.label} style={{textDecoration:'none'}} onClick={handleDrawerClose}>
                        <ListItem button key={item.label}>
                            <ListItemText primary={<Typography color="textPrimary">{item.label}</Typography>} />
                        </ListItem>
                      </Link>
                    )}
                </>}
                {!openSub1&&!openSub2&&!openSub3&&openSub4&&<>
                    <ListItem button key="Back" onClick={handleSub4Close}>
                        <ListItemIcon sx={{minWidth: '32px'}}><KeyboardArrowLeftIcon /></ListItemIcon>
                        <ListItemText primary={<Typography color="textPrimary">Back</Typography>} />
                    </ListItem>
                    <Divider />
                    {other.map((item) =>
                      <Link to={item.path} key={item.label} style={{textDecoration:'none'}} onClick={handleDrawerClose}>
                        <ListItem button key={item.label}>
                            <ListItemText primary={<Typography color="textPrimary">{item.label}</Typography>} />
                        </ListItem>
                      </Link>
                    )}
                </>}
            </List>
          </Box>
        </Drawer>
        <Box component="main" sx={{ flexGrow: 1, overflowY: 'hidden', bgcolor:'white'}}>
          <DrawerHeader/>
          <div style={{position: 'relative'}}>
            <Box mt={0} ml={0} mr={0} mb={0} style={{ textAlign: 'center'}}>
              {children}
            </Box>
          </div>
          <footer style={{ width: '100%', position: 'relative', bottom: 0 }}>
            <div className="c-footer c-footer-light" style={{height: 'fit-content', backgroundColor: theme.palette.background.default, textAlign: 'center'}}>
              <div className="row justify-content-md-center" style={{width: '100%', display: 'contents', backgroundColor: theme.palette.background.default, textAlign: 'center'}}>
                <div className="col col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" style={{textAlign: 'justify', paddingTop: '15px', paddingBottom: '0px', paddingLeft: '15px', paddingRight: '15px', marginTop: '24px'}}>
                  <div style={{paddingLeft: '0px', paddingRight: '0px', paddingBottom: '0px', display: "inline-flex", color: theme.palette.primary.main, cursor: 'pointer'}} onClick={() => history.push('/')}><LogoIllustration height="28px" /></div>
                </div>
                <div className="col col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" style={{textAlign: 'justify', paddingTop: '15px', paddingBottom: '15px', paddingLeft: '15px', paddingRight: '15px'}}>
                  <p className="h6 mb-3" style={{textAlign: 'justify', color: theme.palette.primary.main, opacity: 0.7}}><small><a target="_blank" rel="noreferrer" href="https://davygroup.com/legal/disclaimer" style={{ color: theme.palette.primary.main }}><u>Legal Disclaimer</u></a>&nbsp;&nbsp;|&nbsp;&nbsp;<a target="_blank" rel="noreferrer" href="https://davygroup.com/legal/privacy" style={{ color: theme.palette.primary.main }}><u>Privacy Policy</u></a></small></p>
                  <p className="h6 mb-3" style={{textAlign: 'justify', color: theme.palette.primary.main, opacity: 0.7}}><small>Davy Group, Inc (“Davy“), is a holding company, which owns controlling interest in a collection of companies all over the world. Our corporate infrastructure is designed to support shareholders achieving best return on their investment. While we make every effort to ensure the accuracy and reliability of the information and recommendations provided, the results may vary based on a number of different factors. Our statements and opinions are not intended to be a guarantee of results or investment return and should not be relied upon as such. Davy Group, Inc does not accept any liability for any loss or damage suffered as a result of reliance upon information and opinions provided.</small></p>
                  <p className="h6 mb-3" style={{textAlign: 'left', color: theme.palette.primary.main, opacity: 0.7}}><small>Copyright&nbsp;©&nbsp;2020-2023 Davy&nbsp;Group,&nbsp;Inc</small></p>
                </div>
              </div>
            </div>
          </footer>
        </Box>
      </Box>
    </ThemeProvider>
  );
}

export default PublicTemplate;