import React, {Fragment} from 'react';
import HoverMenu from 'material-ui-popup-state/HoverMenu';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import dataJSON from "../../../data/company.json";
import { useHistory } from 'react-router-dom';
import {
  usePopupState,
  bindHover,
  bindMenu,
} from 'material-ui-popup-state/hooks';

const Other = () => {
  const history = useHistory();
  const other = dataJSON.content[3].data;
  const popupState = usePopupState({
      variant: 'popover',
      popupId: 'otherMenu',
  })
  return (
    <Fragment>
      <Typography variant="text" {...bindHover(popupState)} sx={{ textTransform: 'none', p: 2, paddingBottom: '20px', cursor: 'pointer', fontSize: '12px', width: '100px', color: '#000', "&:hover,&:focus": {backgroundColor: "#263238",color: "#FFF !important"}}} >
        Other <KeyboardArrowDownIcon fontSize='small'/>
      </Typography>
      <HoverMenu
        {...bindMenu(popupState)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
      >
        {other.map((item) => 
          <MenuItem key={item.label} onClick={() => {
            history.push(item.path);
            popupState.close();
          }} sx={{ minWidth: '166px', fontSize: '12px'}}>{item.label}</MenuItem>
        )}
      </HoverMenu>
    </Fragment>
  )
}

export default Other;