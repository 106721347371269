import React, { useState } from 'react';
import { Button, Divider } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { ReactComponent as SVG_1 } from "../../../assets/teamwork.svg";
import HistoryEduTwoToneIcon from '@mui/icons-material/HistoryEduTwoTone';
// import { useHistory } from 'react-router-dom';

const Home = () => {
    const theme = useTheme();
    const [open, setOpen] = useState(false);
    // const history = useHistory();
    const title = "Letter from the Founder";
    const founder = "George Davy";
    const foundersLetter_small = "Davy Group defies the conventions of a typical company, and we have no aspirations to conform to such standards. Our approach is fundamentally different, emphasizing innovation, independence, and the freedom to explore uncharted territories. We are committed to embracing our unique identity, fostering creativity, and encouraging our teams to think beyond the confines of traditional corporate structures. Our mission is to pave the way for unconventional ideas, shaping a future where innovation knows no bounds.";
    const foundersLetter_p1 = "Since the beginning, our constant endeavor has been to achieve more and to utilize our resources for significant and meaningful purposes.";
    const foundersLetter_p2 = "Its our belief that as time passes, companies often settle into a routine, making only minor adjustments. However, in todays world, embracing discomfort is essential to remain pertinent and innovative.";
    const foundersLetter_p3 = "Our company is in the business of starting new things, and we believe in enforcing improvements in terms of transparency and accountability.";
    const foundersLetter_p4 = "What is Davy Group? Davy Group is mostly a collection of companies. We believe in prospering by empowering the daring to accomplish the remarkable. In general, our model is to have a strong CEO who runs each business, with me in service to them as needed. We will rigorously handle capital allocation and work to make sure each business is executing well. We’ll also make sure we have a great CEO for each business, and we’ll determine their compensation. Fundamentally, we believe this allows us more management scale, as we can run things independently that aren’t very related.";
    const foundersLetter_p5 = "For me this is a very exciting new chapter—the birth of Davy Group. We liked the name Davy because it means a legally binding statement or oath. I should add that our intention is not to create a large consumer brand with associated products. The primary goal is to foster independence among Davy Group companies, allowing them to cultivate their unique brands.";
    const foundersLetter_p6 = "We are excited about…";
    const founderPoints = [
        {
            id: 0,
            description: "Getting more ambitious things done."
        },
        {
            id: 1,
            description: "Taking the long-term view."
        },
        {
            id: 2,
            description: "Empowering great entrepreneurs and companies to flourish."
        },
        {
            id: 3,
            description: "Investing at the scale of the opportunities and resources we see."
        },
        {
            id: 4,
            description: "Improving the transparency and oversight of what we’re doing."
        }
    ];

    const handleReadMore = () => {
        setOpen(!open);
    };

    return (
        <>
            <div className="container-fluid">
                <div className="animated fadeIn">
                    <div className="row justify-content-md-center" style={{backgroundColor: theme.palette.common.white, padding: 0}}>
                        <div className="col col-12 col-sm-12 col-md-12 col-lg-12 col-xl-11">
                            <div className="row mt-4 mb-4 justify-content-md-center">
                                <div className="col col-12 col-sm-12 col-md-12 col-lg-7 col-xl-7">
                                    <div className='mt-0 mb-0 font-weight-light' style={{textAlign: 'left', verticalAlign: 'middle', padding: '10px', paddingTop: '24px'}}>
                                        <p className="h1 mb-0 font-weight-light" style={{fontFamily: 'inherit', textAlign: 'left'}}><HistoryEduTwoToneIcon sx={{ fontSize: '64px', ml: "-14px" }}/></p>
                                        <p className="h1 mb-0 font-weight-light" style={{fontFamily: 'inherit', fontSize: '36px', textAlign: 'left', fontStyle: 'normal', letterSpacing: 1.2}}>{title}</p>
                                        {open?(<>
                                            <p className="h5 mt-3 font-weight-light" style={{fontFamily: 'inherit', fontSize: '14px', textAlign: 'justify', fontStyle: 'normal', letterSpacing: 1.2}}>{foundersLetter_p1}</p>
                                            <p className="h5 mt-3 font-weight-light" style={{fontFamily: 'inherit', fontSize: '14px', textAlign: 'justify', fontStyle: 'normal', letterSpacing: 1.2}}>{foundersLetter_p2}</p>
                                            <p className="h5 mt-3 font-weight-light" style={{fontFamily: 'inherit', fontSize: '14px', textAlign: 'justify', fontStyle: 'normal', letterSpacing: 1.2}}>{foundersLetter_p3}</p>
                                            <p className="h5 mt-3 font-weight-light" style={{fontFamily: 'inherit', fontSize: '14px', textAlign: 'justify', fontStyle: 'normal', letterSpacing: 1.2}}>{foundersLetter_p4}</p>
                                            <p className="h5 mt-3 font-weight-light" style={{fontFamily: 'inherit', fontSize: '14px', textAlign: 'justify', fontStyle: 'normal', letterSpacing: 1.2}}>{foundersLetter_p5}</p>
                                            <p className="h5 mt-3 font-weight-light" style={{fontFamily: 'inherit', fontSize: '14px', textAlign: 'justify', fontStyle: 'normal', letterSpacing: 1.2}}>{foundersLetter_p6}</p>
                                            {founderPoints.map((item) =>
                                                <p key={item.id} className="h5 ml-3 font-weight-light" style={{fontFamily: 'inherit', fontSize: '14px', textAlign: 'left', fontStyle: 'normal', letterSpacing: 1.2}}>&#8226;&nbsp;{item.description}</p>
                                            )}
                                        </>):(<>
                                            <p className="h5 mt-3 font-weight-light" style={{fontFamily: 'inherit', fontSize: '14px', textAlign: 'justify', fontStyle: 'normal', letterSpacing: 1.2}}>{foundersLetter_small}</p>
                                        </>)}
                                        <div style={{ textAlign: 'inherit' }}>
                                            <Button className="mt-2 mb-2" variant="text" size="small" color="info" sx={{ p: 0, borderRadius: 0, borderWidth: 0, fontSize: '12px' }} onClick={handleReadMore}>
                                                {open?"Read less":"Read more"}
                                            </Button>
                                        </div>
                                        <p className="h2 mb-0 font-face-ha" style={{ padding: '16px', color: '#2D51A3'}}>{founder}</p>
                                        <p className="h6 mb-0 mt-2 ml-2" style={{fontFamily: 'inherit', color: '#000', fontWeight: 400}}>{founder}</p>
                                        <Divider sx={{ mt: 1, width: '300px'}}/>
                                    </div>
                                </div>
                                <div className="col col-12 col-sm-12 col-md-12 col-lg-5 col-xl-5 d-none d-lg-block">
                                    <div className='mb-0 font-weight-light' style={{textAlign: 'left'}}>
                                        <div style={{ textAlign: 'center', padding: '0px', marginTop: '24px'}}>
                                            <SVG_1 width={400} height={400}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Home;